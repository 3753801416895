import React from 'react';
import { graphql } from 'gatsby';
import LegalPage from 'components/pages/LegalPage';

const seo = {
  title: 'Q°emotion - Our Privacy Policy',
  keywords: [],
  description:
    'Q°emotion is able to transform your customer and employees comments into emotional analytics and insights. Read our Privacy Policy here.',
};

export default props => <LegalPage {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    dataBlockText: markdownRemark(
      frontmatter: { pageType: { eq: "privacyPolicy" }, lang: { eq: "en" } }
    ) {
      rawMarkdownBody
    }
  }
`;
